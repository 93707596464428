import { AuthedHttp } from '../../helpers/AuthedHttp';
import { UpdateOfferingsDto } from '../dtos';
import { PlanType } from '../types/planType';
import {
  DowngradePlanRequest,
  UpgradePlanRequest,
} from '../types/updatePlanRequest';
import { Routes } from './routes';

import { getEmailApiPlan } from '../middle_tier/common/adapters';
import { NewPlanDto } from '../middle_tier/new_plan/service';
import { OES } from '../middle_tier/services/oes/service';
import { createSubscriptionPayment } from '../payment_methods/service';
import { MarketingCampaignsSubscription } from '../types/marketingCampaignsSubscription';
import { ProductId } from '../types/productId';
import { SubscriptionPayment } from '../types/subscriptionPayment';
import { EmailApiPlanTier } from '../types/emailApiPlan';
import { MarketingCampaignsPlanTier } from '../types/marketingCampaignsPlan';

export interface UpdatedOffering {
  name: string;
  end_date: string;
  start_date: string;
  quantity: number;
}

export interface UpdatedOfferingsDto {
  offerings: UpdatedOffering[];
}

export const startTrial40k = async (
  marketingCampaignsSubscription: MarketingCampaignsSubscription
): Promise<UpgradePlanRequest> => {
  const upgradePlanRequest = {
    removeEase: false,
    planType: PlanType.EmailApi,
    isUpgrade: true,
    planId: ProductId.SG_EI_TRIAL_40K_V1,
    planName: 'Trial 40K',
  } as UpgradePlanRequest;

  const newPlanRequest = {
    offerings: [
      { name: ProductId.SG_EI_TRIAL_40K_V1 },
      { name: marketingCampaignsSubscription.planId },
    ],
  } as NewPlanDto;

  try {
    const newPlan = await OES.getNewPlanAccount(newPlanRequest);
    upgradePlanRequest.packageId = newPlan.entitlements.compat_combo_uuids[0];
    return upgradePlanRequest;
  } catch (error) {
    console.log('error trying to update to trial 40k', error);
    return Promise.reject('unable to upgrade to trial 40k');
  }
};

export const upgradeWithNewPayment = async (
  subscriptionPayment: SubscriptionPayment,
  upgradePlanRequest: UpgradePlanRequest
) => {
  await createSubscriptionPayment(subscriptionPayment);
  await upgradeService(upgradePlanRequest);
};

export const upgradeService = async (
  request: UpgradePlanRequest | DowngradePlanRequest
): Promise<UpdatedOfferingsDto> => {
  try {
    // When a customer wants to downgrade their plan to EI/MC end-of-trial (non self-serve offerings)
    // they can't do this through the tranditional SSAPI update offerings endpoint. We have to use
    // the cancellation endpoint.
    // https://twilio-engineering.atlassian.net/browse/CLKRAKEN-2544
    if (
      [
        EmailApiPlanTier.EndOfTrial,
        MarketingCampaignsPlanTier.EndOfTrial,
      ].includes(request.planTier)
    ) {
      const cancelDto = {
        product_type: [request.planType === PlanType.EmailApi ? 'ei' : 'mc'],
      };

      const cancelResponse = await AuthedHttp.patch<UpdatedOfferingsDto>(
        Routes.OfferingsCancel,
        cancelDto
      );
      if (!cancelResponse.ok) {
        return Promise.reject('unable to change subscription');
      }

      const updatedOfferings = await cancelResponse.json();
      return Promise.resolve(updatedOfferings);
    }

    const offeringsUpdate = {
      add: [{ name: request.planId }],
    } as UpdateOfferingsDto;
    const ssapiResponse = await AuthedHttp.patch<UpdatedOfferingsDto>(
      Routes.OfferingsPatch,
      offeringsUpdate
    );

    if (!ssapiResponse.ok) {
      return Promise.reject('unable to change subscription');
    }

    const account = await OES.getAccount();
    const emailPlan = getEmailApiPlan(account.offerings);

    if (!emailPlan) {
      return Promise.reject('email plan name is undefined');
    }

    // https://twilio-engineering.atlassian.net/browse/CLRP-3088
    // an account may wish to remove the ease offering when upgrading to a new plan
    // because the ssapi does not yet support updating multiple offerings in a single request
    // we need to handle the case where we need to remove the ease offering separately
    // because we do not set dates on the update offerings request we're relying on
    // the logic in ssapi to set the end date which happens to be the end of the month
    // instead of immediately for the current legacy flow
    const ease = await OES.getAssociatedEaseOffering(emailPlan.name);
    if ('removeEase' in request && request.removeEase && ease?.name) {
      const easeUpdate = {
        remove: [{ name: ease.name }],
      } as UpdateOfferingsDto;

      const ssapiResponseRemove = await AuthedHttp.patch<UpdatedOfferingsDto>(
        Routes.OfferingsPatch,
        easeUpdate
      );
      if (!ssapiResponseRemove.ok) {
        return Promise.reject('unable to update ease');
      }
    }

    if (request.isUpgrade) {
      // We could fail to charge the cost of the upgrade to the user's card asynchronously.
      // Poll for a payment declined state from OES and show the payment failure banner if needed.
      if (window.SendGridTiara.pollPaymentStatus) {
        window.SendGridTiara.pollPaymentStatus();
      }
    }

    const updatedOfferings = await ssapiResponse.json();
    return Promise.resolve(updatedOfferings);
  } catch {
    return Promise.reject('unable to change subscription');
  }
};
