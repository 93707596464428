import { AuthedHttp } from '../../helpers/AuthedHttp';
import { ResponseErrorsDto } from '../../helperTypes/responseError';
import { UpdatedOfferingsDto } from '../subscription_changes/service';
import { Routes } from './routes';

export async function cancelAccount(): Promise<
  UpdatedOfferingsDto | ResponseErrorsDto
> {
  return AuthedHttp.patch<UpdatedOfferingsDto>(
    Routes.OfferingsCancelPatch,
    null
  )
    .then(async (response) => {
      if (!response.ok) {
        const err = await response.json();
        return Promise.reject(err);
      }
      return response.json();
    })
    .then((result) => {
      return result as UpdatedOfferingsDto;
    })
    .catch(() => {
      return {
        errors: [
          {
            field: 'offerings_cancel',
            message:
              'An error occurred attempting to cancel your subscriptions.',
          },
        ],
      };
    });
}
