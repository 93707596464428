import { ActionTypes } from './actionTypes';
import { CancelAccountRequestBody } from '../types/cancelAccount';
import { ActionsUnion, createAction } from '../actionBuilder';
import { ResponseErrorsDto } from 'src/helperTypes/responseError';

export const Actions = {
  cancelAccountRequest: (cancelAccountRequestBody: CancelAccountRequestBody) =>
    createAction(ActionTypes.CancelAccountRequest, cancelAccountRequestBody),
  cancelAccountSuccess: () => createAction(ActionTypes.CancelAccountSuccess),
  cancelAccountFailure: (errors: ResponseErrorsDto) =>
    createAction(ActionTypes.CancelAccountFailure, errors),
  resetCancelAccountInfo: () =>
    createAction(ActionTypes.ResetCancelAccountInfo),
};

export type Actions = ActionsUnion<typeof Actions>;

export default Actions;
